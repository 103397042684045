import axios from "axios"

const editProfile = function (data) {
    return new Promise((resolve, reject) => {
        axios.put(`/account/manage/update/editprofile`, data).then(resp => {
            resolve(resp)
        }).catch(err => {
            // http error
            reject(err)
        })
    })
}

const getInfo = function () {
    return axios.get(`/account/tula/info/admin`)
}

export const getCreditPointData = function () {
    return axios.get(`/company/detail/admin/credit-point`)
}

export const changePassword = function (data) {
    return axios.post(`/company/detail/admin/change-pwd`, data)
}

export const updateLogo = function (data) {
    return axios.post(`/account/manage/update/image`, data)
}

export const uploadImageCover = function (data) {
    return axios.put(`/company/manage/update/coverimg`, data)
}
export const uploadImageLogo = function (data) {
    return axios.put(`/company/manage/update/logoimg`, data)
}

export default { getInfo, editProfile, getCreditPointData, changePassword, updateLogo, uploadImageCover, uploadImageLogo }