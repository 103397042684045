import axios from "axios"
import Vue from "vue"

const charge = function (data) {
    return new Promise((resolve, reject) => {
        axios.post(`/finance/omise/charge`, data).then(resp => {
            resolve(resp)
        }).catch(err => {
            // http error
            reject(err)
        })
    })
}

export const addCard = function (data) {
    return axios.post(`/finance/omise/card`, data)
}

const listBillingHistory = function () {
    return axios.get(`/finance/omise/history`)
}

const listSalesHistory = function () {
    return axios.get(`/finance/student/omise/sales`)
}

const listCards = function () {
    return axios.get(`/finance/omise/cards`)
}

const deletecard = function (cardId) {
    return axios.delete(`/finance/omise/card`, {
        data: {
            cardId
        }
    })
}

export const getReceipt = function (id) {
    return axios.get(`/finance/omise/receipt/${id}`)
}

export default {
    charge,
    listBillingHistory,
    listSalesHistory,
    listCards,
    deletecard,
    getReceipt,
    addCard
}