import Vue from "vue"

const saveWithdraw = function (data) {
    return new Promise((resolve, reject) => {
        Vue.axios.post(`/finance/withdrawal/request`, data).then(resp => {
            resolve(resp)
        }).catch(err => {
            // http error
            reject(err)
        })
    })
}

const listWithdrawHistory = function () {
    return Vue.axios.get(`/finance/withdrawal/history`)
}

/**
 * 
 * @param {*} data 
 * @returns {Promise}
 */
export const createTransfer = (data) => {
    return Vue.axios.post(`/finance/omise/transfer`, data)
}

export const getSummarySales = function () {
    return Vue.axios.get(`/finance/omise/report/summary-sales`)
}

export const getWithdrawableAmount = function () {
    return Vue.axios.get(`/finance/omise/withdrawable-amount`)
}

export const getESlip = function (id) {
    return Vue.axios.get(`/finance/withdrawal/e-slip/${id}`)
}

export default {
    saveWithdraw,
    listWithdrawHistory,
    createTransfer,
    getSummarySales,
    getWithdrawableAmount,
    getESlip
}