import Vue from "vue"

export const bankList = [
    {
        code: "bay",
        text: "Bank of Ayudhya (Krungsri)"
    },
    {
        code: "bbl",
        text: "Bangkok Bank"
    },
    {
        code: "cimb",
        text: "CIMB Thai Bank"
    },
    {
        code: "gsb",
        text: "Government Savings Bank"
    },
    {
        code: "ibank",
        text: "Islamic Bank of Thailand"
    },
    {
        code: "kbank",
        text: "Kasikorn Bank"
    },
    {
        code: "kk",
        text: "Kiatnakin Bank"
    },
    {
        code: "ktb",
        text: "Krungthai Bank"
    },
    {
        code: "scb",
        text: "Siam Commercial Bank"
    },
    {
        code: "tbank",
        text: "Thanachart Bank"
    },
    {
        code: "tmb",
        text: "TMB Bank"
    },
    {
        code: "uob",
        text: "United Overseas Bank (Thai)"
    },
]

export const getBankList = () => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            return resolve({
                data: bankList
            })
        });
    })
}

export const getBankAccount = () => {
    return Vue.axios.get(`/finance/omise/recipient`)
}

export const addBankAccount = (data) => {
    return Vue.axios.post(`/finance/omise/recipient`, data)
}

export const updateBankAccount = (id, data) => {
    return Vue.axios.put(`/finance/omise/recipient/${id}`, data)
}



export default { getBankAccount, getBankList, addBankAccount, updateBankAccount }