<template>
  <div>
    <!-- <div> -->
    <!-- <div class="text-center">
      <v-img
        :src="info.logo"
        class="avatar avatar-large rounded-circle shadow d-block mx-auto"
        alt=""
      />
      <div class="card-body py-3 px-0 content">
        <h5 class="mb-0">{{ info.firstName + " " + info.lastName }}</h5>
      </div>

    </div>-->

    <ul
      class="
        nav nav-pills nav-justified
        flex-column
        bg-white
        rounded
        shadow
        p-3
        mb-0
      "
      id="pills-tab"
      role="tablist"
    >
      <li class="nav-item">
        <router-link to="/account/profile" class="nav-link rounded">
          <div class="text-left py-1 px-3">
            <h6 class="mb-0">
              <i class="uil uil-user h5 align-middle mr-2 mb-0"></i>
              บัญชีของฉัน
            </h6>
          </div>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link to="/account/security" class="nav-link rounded">
          <div class="text-left py-1 px-3">
            <h6 class="mb-0">
              <i class="uil uil-shield-check h5 align-middle mr-2 mb-0"></i>
              ตั้งค่าความปลอดภัย
            </h6>
          </div>
        </router-link>
      </li>

      <li class="nav-item mt-2">
        <router-link to="/account/creditcard" class="nav-link rounded">
          <div class="text-left py-1 px-3">
            <h6 class="mb-0">
              <i class="uil uil-transaction h5 align-middle mr-2 mb-0"></i>
              การชำระเงิน
            </h6>
          </div>
        </router-link>
      </li>

      <li class="nav-item mt-2">
        <router-link to="/account/point" class="nav-link rounded">
          <div class="text-left py-1 px-3">
            <h6 class="mb-0">
              <i class="uil uil-receipt-alt h5 align-middle mr-2 mb-0"></i>
              เครดิตพอยท์
            </h6>
          </div>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link to="/account/income-config" class="nav-link rounded">
          <div class="text-left py-1 px-3">
            <h6 class="mb-0">
              <i class="uil uil-money-withdrawal h5 align-middle mr-2 mb-0"></i>
              ผูกบัญชีรายได้
            </h6>
          </div>
        </router-link>
      </li>

      <!-- <li class="nav-item mt-2">
        <router-link to="/account/balance" class="nav-link rounded">
          <div class="text-left py-1 px-3">
            <h6 class="mb-0">
              <i class="uil-bring-front h5 align-middle mr-2 mb-0"></i>
              การถอนเงิน
            </h6>
          </div>
        </router-link>
      </li> -->

      <li class="nav-item mt-2">
        <button type="button" @click="logout" class="nav-link rounded">
          <div class="text-left py-1 px-3">
            <h6 class="mb-0">
              <i class="uil uil-sign-out-alt h5 align-middle mr-2 mb-0"></i>
              ออกจากระบบ
            </h6>
          </div>
        </button>
        <!--end nav link-->
      </li>
      <!--end nav item-->
    </ul>
    <!--end nav pills-->
    <!-- </div> -->
    <!-- <div class="bg-light rounded shadow p-4">
      <h5>Profile</h5>
      <ul class="list-unstyled mt-4 mb-0">
        <li class="mt-2">
          <router-link to="/account/profile" class="text-muted">
            <i class="mdi mdi-arrow-right text-primary mr-1"></i>
            Information
          </router-link>
        </li>
      </ul>

      <h5 class="pt-4">Billing</h5>
      <ul class="list-unstyled mt-4 mb-0">
        <li class="mt-2">
          <router-link to="/account/billing" class="text-muted">
            <i class="mdi mdi-arrow-right text-primary mr-1"></i
              >Payment
          </router-link>
        </li>
        <li class="mt-2">
          <router-link to="/account/creditcard" class="text-muted">
            <i class="mdi mdi-arrow-right text-primary mr-1"></i
              >Credit Card
          </router-link>
        </li>
        <li class="mt-2">
          <router-link to="/account/sales" class="text-muted">
            <i class="mdi mdi-arrow-right text-primary mr-1"></i
            >Sales
          </router-link>
        </li>
        <li class="mt-2">
          <router-link to="/account/balance" class="text-muted">
            <i class="mdi mdi-arrow-right text-primary mr-1"></i
            >Balance
          </router-link>
        </li>
      </ul>

      <ul class="list-unstyled mt-4 mb-0">
        <li class="mt-2">
          <button type="button" @click="logout" class="text-danger">
            <h5>Logout</h5>
          </button>
        </li>
      </ul>
    </div>-->
  </div>
</template>
<script>
import Vue from "vue";
import infoService from "@/services/infoService";
import { EventBus } from "@/event-bus";
export default {
  data: () => ({
    info: {
      firstName: null,
      lastName: null,
      logo: null,
    },
  }),
  mounted() {
    this.getInfo();

    var links = document.getElementsByClassName("nav-link");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
    }
  },
  methods: {
    getInfo() {
      infoService
        .getInfo()
        .then((resp) => {
          if (resp.data.status == true && resp.data.result != null) {
            let info = resp.data.result.info;

            this.info.firstName = info.firstName;
            this.info.lastName = info.lastName;
            this.info.logo = info.companies[0].coverImg;

            Vue.$cookies.set("user_info", info);
          } else {
            throw "Can not get information user";
          }
        })
        .catch((err) => {});
    },
    async logout() {
      this.$bvModal
        .msgBoxConfirm("คุณต้องการที่จะออกจากระบบใช่หรือไม่ ?", {
          title: "ยืนยัน",
          centered: true,
          okTitle: "ใช่",
          cancelTitle: "ไม่ใช่",
        })
        .then((val) => {
          if (val) {
            EventBus.$emit("signOut");
          }
        });
    },
  },
};
</script>