<template>
  <div>
    <!-- <div class="rounded shadow p-4"> -->
    <!-- <div class="d-flex align-items-center justify-content-between">
      <h5 class="mb-0">Payment Methods</h5>
    </div> -->
    <template v-if="toAccount != null">
      <h6 class="mb-0">บัญชีปลายทาง</h6>
      <b-container fluid class="mt-2 mb-0 mx-0 px-0">
        <b-row no-gutters>
          <b-col cols="12" md="6" lg="6">
            <div class="small text-muted">ธนาคาร</div>
            <div>
              {{ toAccount.bank_account.brand }}
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <div class="small text-muted">หมายเลขบัญชี</div>
            <div>******{{ toAccount.bank_account.last_digits }}</div>
          </b-col>
          <b-col cols="12" md="6" lg="6" class="my-3">
            <div class="small text-muted">ชื่อบัญชี</div>
            <div>
              {{ toAccount.bank_account.name }}
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="6" class="my-3">
            <div class="small text-muted">คำอธิบาย</div>
            <div>
              {{ toAccount.description }}
            </div>
          </b-col>
        </b-row>
      </b-container>
      <p class="border-bottom mt-2"></p>
    </template>
    <div class="pt-0">
      <b-form
        ref="form"
        @submit.stop.prevent="onSubmit"
        novalidate
        :validated="formValidated"
      >
        <b-container fluid class="mt-0">
          <b-row no-gutters>
            <b-col cols="12">
              <b-form-group :label="`จำนวนเงิน`" class="mb-0">
                <b-form-input
                  type="number"
                  step="1"
                  v-model.number="amount"
                  :required="true"
                  :min="1000"
                  :max="maxAmount"
                  placeholder="ระบุจำนวนเงินที่ต้องการถอน"
                ></b-form-input>
                <b-form-invalid-feedback>
                  กรุณาระบุจำนวนเงิน >=
                  {{ (config.minWithdraw || 0) | numFormat("0,0.00") }} THB
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-4">
              <label class="label mb-0"
                >ยอดเงินสุทธิที่จะได้รับหลังจากหักค่าธรรมเนียมฯ</label
              >
              <div>
                <h6>
                  {{ (amount * (1 - (config.fee || 0))) | numFormat("0,0.00") }}
                  THB
                </h6>
              </div>
            </b-col>
            <b-col cols="12" class="mt-3">
              <label class="label mb-0">ค่าธรรมเนียม & ค่าบำรุงรักษาระบบ</label>
              <div>
                <h6>
                  {{ (amount * (config.fee || 0)) | numFormat("0,0.00") }} THB
                </h6>
              </div>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-form-group :label="`หมายเหตุ`" class="mb-0">
                <b-form-input
                  v-model="note"
                  placeholder=" --- ไม่บังคับ ---"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </div>
    <!-- </div>  -->
  </div>
</template>

<script>
import { PlusIcon } from "vue-feather-icons";
export default {
  components: {
    PlusIcon,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    toAccount: {
      type: Object,
      default: null,
    },
    maxAmount: {
      type: Number,
      default: 1,
    },
    config: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    paymentMethod: "bank",
    accountName: "",
    accountNo: "",
    bankName: "",
    amount: 1,
    note: "",
    formValidated: false,
    minWithdraw: 1,
  }),
  methods: {
    init() {
      this.formValidated = false;
      this.amount = this.config.minWithdraw;
      this.minWithdraw = this.config.minWithdraw;
    },
    validateForm() {
      this.formValidated = true;
      return this.$refs.form.checkValidity();
    },
    getWithdrawInfo() {
      let valid = this.validateForm();
      if (!valid) return null;

      return {
        amount: this.amount,
        note: this.note,
      };
    },
    clear() {
      this.formValidated = false;
      this.accountName = "";
      this.accountNo = "";
      this.bankName = "";
      this.amount = 0;
      this.note = "";
    },
  },
};
</script>

<style scoped>
.border-bottom-cus {
  border-bottom: 5px solid !important;
  color: rgba(0, 201, 167, 0.1);
}
</style>