
/**
 * Index-classic-saas component
 */
import { CheckCircleIcon, XCircleIcon, ClockIcon } from 'vue-feather-icons'

import WithdrawForm from "@/components/withdraw-form";
import omiseService from "../../../services/omiseService";
import withdrawService from "../../../services/withdrawService";
import accountMenu from "@/views/account/menu.vue";
import bankService from "../../../services/bankAccount";
export default {
    components: {
        CheckCircleIcon,
        XCircleIcon,
        ClockIcon,

        accountMenu,
        WithdrawForm
    },
    data: () => ({
        withdrawConfig: {},
        minWithdraw: 1000,
        modalWithdrawShow: false,
        withdrawHistory: [],
        balance: 0,
        headers: [
            {
                text: "วันที่",
                align: "center",
                sortable: false,
                value: "created_at",
            },
            {
                text: "จำนวนเงิน (THB)",
                align: "center",
                sortable: false,
                value: "amount",
            },
            {
                text: "ค่าธรรมเนียม (THB)",
                align: "center",
                sortable: false,
                value: "fee",
            },
            {
                text: "ไปยังบัญชี",
                align: "left",
                sortable: false,
                value: "detail",
            },
            {
                text: "สถานะ",
                align: "center",
                sortable: false,
                value: "status",
            },
            {
                text: "หมายเหตุ",
                align: "left",
                sortable: false,
                value: "note",
            },
            {
                text: "e-Slip",
                align: "left",
                sortable: false,
                value: "slip",
            },
        ],
        currentAccount: null,
        withdrawable: false,
        overlay: false,
        progressModal: false,
        instanceShowForm: false
    }),
    mounted() {
        // this.getBillingHistory();

        if (this.enableOmiseFeature) {
            this.getCurrentAccount();
        }
    },
    watch: {
        modalWithdrawShow(val) {
            if (val === true) {
                setTimeout(() => {
                    this.$refs.withdrawForm.init()
                });
            }
        }
    },
    computed: {
        enableOmiseFeature() {
            return this.$store.state.enableOmiseFeature || false
        }
    },
    methods: {
        getBillingHistory() {
            withdrawService.getWithdrawableAmount().then(resp => {

                this.balance = resp.data.amount
                this.withdrawConfig = resp.data

                return withdrawService.listWithdrawHistory()
            }).then((resp) => {
                if (resp.data.status == true && resp.data.result != null) {
                    this.withdrawHistory = resp.data.result;
                    delete resp.data.result;
                } else {
                    throw "Can not get customer's cards";
                }
            }).catch((err) => {
                this.withdrawHistory = [];
            }).finally(() => {
                this.overlay = false
                if (this.$route.query.form == true && this.withdrawable == true && this.instanceShowForm == false) {
                    this.showDialogWithdraw()
                    this.instanceShowForm = true
                }
            });

        },
        getCurrentAccount() {
            this.overlay = true
            bankService
                .getBankAccount()
                .then((resp) => {
                    if (resp.data.length > 0) {
                        this.hasSetup = true;
                        const currentBank = resp.data[0];
                        if (currentBank.verified === true && currentBank.active === true) {
                            this.withdrawable = true
                        } else {
                            this.withdrawable = false
                        }

                        this.currentAccount = currentBank;
                    } else {
                        // not setup yet
                        this.currentAccount = null;
                        this.withdrawConfig = {}
                        this.withdrawable = false
                    }
                })
                .catch((err) => {
                    this.overlay = false
                    // do nothing
                }).finally(() => {
                    this.getBillingHistory()
                });
        },
        showDialogWithdraw() {
            if (this.currentAccount == null) {
                // alert setup bank account first
                // then routing to bank account page
                this.$router.push({
                    name: "BankAccount"
                })
            } else {
                if (this.balance < this.minWithdraw) {
                    this.$bvModal.msgBoxOk(
                        `ยอดเงินที่ท่านจะถอนไม่ถึงเกณฑ์ขั้นต่ำ (ขั้นต่ำสุด=${this.minWithdraw})`,
                        {
                            title: "กรุณาระบุยอดเงินมากขึ้น",
                            centered: true,
                        }
                    )
                } else {
                    this.modalWithdrawShow = true
                }

            }

        },
        onTransferCreated() {
            this.modalWithdrawShow = false
            this.getBillingHistory()
        },
        handleWithdrawFormOk(bvModalEvt) {
            bvModalEvt.preventDefault();

            let withdraw = this.$refs.withdrawForm.getWithdrawInfo();
            if (withdraw == null) {
                return; // form valid
            }

            if (withdraw.amount <= 0) {
                this.modalWithdrawShow = false
                this.$bvModal.msgBoxOk('กรุณาระบุยอดเงิน > 0', {
                    title: "เกิดข้อผิดพลาด",
                    okVariant: "danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                })
                return
            }

            // this.createWithdrawRequest(withdraw);
            this.progressModal = true
            withdrawService.createTransfer(withdraw)
                .then((resp) => {
                    if (resp.data == true) {
                        this.$refs.withdrawForm.clear();
                        this.$bvModal.msgBoxOk('คำร้องการถอนเงินได้ถูกสร้างขึ้นแล้ว กรุณารอขั้นตอนในการดำเนินการ ทางระบบจะแจ้งไปยังอีเมลของท่านต่อไป').then(() => {
                            this.getBillingHistory()
                        })
                    } else {
                        // do nothing
                    }
                })
                .catch((err) => {
                    // err.response.data.message
                    this.$bvModal.msgBoxOk("เกิดข้อผิดพลาดบางอย่างในการสร้างรายการถอนเงิน เช่น ท่านไม่สามารถสร้างรายการถอนเงินพร้อมกันได้หากท่านได้สร้างรายการถอนเงินไว้ก่อนหน้าแล้ว เป็นต้น", {
                        title: "เกิดข้อผิดพลาด",
                        okVariant: "danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .finally(() => {
                    this.modalWithdrawShow = false;
                    this.progressModal = false
                });
        },
        createWithdrawRequest(withdraw) {
            let accountNoWithoutFormat = withdraw.accountNo.replaceAll(" - ", "");
            let data = {
                type: 'bank', // 'promptpay
                amount: withdraw.amount,
                detail: {
                    account: withdraw.accountName,
                    accountNo: accountNoWithoutFormat,
                    bank: withdraw.bankName,
                }
            }

            // type: 'promptpay',
            // detail: {
            //     type: 'tel', // 'idCard'   
            //     promptpayNo: withdraw.promptpayNo,
            // }

            withdrawService
                .saveWithdraw(data)
                .then((resp) => {
                    if (resp.data.status == true && resp.data.result != null) {
                        this.modalWithdrawShow = false;
                        this.getBillingHistory();
                    } else {
                        throw "Can not save withdraw request";
                    }
                })
                .catch((err) => {
                    this.modalWithdrawShow = false;

                    this.$bvModal.msgBoxOk("เกิดข้อผิดพลาดบางอย่างในการสร้างรายการถอนเงิน เช่น ท่านไม่สามารถสร้างรายการถอนเงินพร้อมกันได้หากท่านได้สร้างรายการถอนเงินไว้ก่อนหน้าแล้ว เป็นต้น", {
                        title: "เกิดข้อผิดพลาด",
                        size: "sm",
                        okVariant: "danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });

                });
        },
    },
};
